<template>
  <div v-if="race && race.route">
    <v-dialog v-model="visible" max-width="90%">
      <v-card>
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark class="ml-2" @click="visible = false">
            <v-icon>fa-times-circle</v-icon>
          </v-btn>
          <v-toolbar-title>Add a badge along the (virtual) course</v-toolbar-title>
          <v-spacer/>
          <v-btn dark class="ml-2" @click="saveMarker">
            <v-icon class="mr-2">fa-check</v-icon>
            Add Badge
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4"><strong>Drag the marker along the course</strong> until it's at the right location and click 'Add Badge' to finalize the badge configuration.</v-card-text>
        <v-card-text>Note: uploading a new course map after adding badges may mis-align the badges so please make sure the course is correct before adding badges.</v-card-text>
        <LeafletMap
          ref="leaflet"
          :auto-load="false"
          style="height: 400px; width: 100%; z-index: 2;"
          :loader="loadMap"
          />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import tenants from '@/data/tenants.config'
import LeafletMap from './LeafletMap.vue';
const tenant = tenants.current();

export default {
  name: "RaceGeoFenceDialog",
  components: {
    LeafletMap
  },
  props: {
    event: Object,
    race: Object,
    color: String,
  },
  data() {
    return {
      visible: false,
      zoom: 11,
      value: 0,
      //map: null,
      marker: null,
      badgeOptions: null,
      polyline: null,
      routePolyDecoded: null,
      courseLatLngs: null,
      markerIcon: window.L.icon({
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
        // specify the path here
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
        shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
      }),
    }
  },
  async mounted() {

  },
  methods: {
    async open() {
      this.visible = true;
    },

    async saveMarker() {
      this.visible = false;
      const badge = {
        metric: 'SCORE',
        unit: 'FIXED',
        color: tenant.theme.primary,
        show_map: true,
        active: true,
        value: this.value,
        ...this.badgeOptions,
      };
      console.log('Saving map now.', badge);
      this.$emit('save', badge);
    },

    async ensureMapLoaded() {
      await this.initMap();
      //this.loadMap();
    },

    async initMap() {
      if (this.race == null || this.event == null || this.map != null) {
        console.log('No data (yet) to load.', this.race, this.event, this.map);
        return;
      }
      await this.$nextTick();
      console.log('Preparing course map.');
      if (!this.$refs.leaflet) {
        return;
      }
      await this.$refs.leaflet.initMap();
    },

    loadMap() {
      this.routePolyDecoded = this.race.route == null ? null : PolylineUtil.decode(this.race.route);
      this.courseLatLngs = this.race.route == null ? null : this.routePolyDecoded.map(x => window.L.latLng(x));
      //console.log('Showing course map now:', this.routePolyDecoded, this.$refs.leaflet);
      const map = this.$refs.leaflet.map;
      this.$refs.leaflet.addPolyline(this.routePolyDecoded, { color: '#ffffff', weight: 8, stroke: true, fill: false });
      this.polyline = this.$refs.leaflet.addPolyline(this.routePolyDecoded, { color: '#008AFF', weight: 5, stroke: true, fill: false });
      if (!this.isCustomMap) this.$refs.leaflet.map.setMaxBounds(null); // reset to prevent clipping when course is changed
      this.$refs.leaflet.map.fitBounds(this.polyline.getBounds().pad(.1 /* 10% */));
      if (!this.isCustomMap) this.$refs.leaflet.map.setMaxBounds(this.polyline.getBounds().pad(.5 /* 50% */));

      //this.marker = this.$refs.leaflet.addMarker(this.startCoord);
      this.marker = new L.marker(this.startCoord, {draggable:'true', icon: this.markerIcon, title: 'Drag badge location'}).addTo(map);
      const self = this;
      this.marker.on('dragend', function(event){
        var marker = event.target;
        var position = self.getNearestPointToPolyline(marker.getLatLng(), self.courseLatLngs);
        var distOverCourse = self.getDistanceTillCoord(self.courseLatLngs, position);
        var totalCourse = self.getDistanceTillCoord(self.courseLatLngs, {});
        var meters = Math.round((distOverCourse/totalCourse) * (self.race.dist || self.race.collective_goal));
        if (self.race.scoring === 'STEPS') {
          self.value = meters; // actually: steps
        }
        else if (self.race.scoring === 'TIME') {
          self.value = meters; // actually: seconds
          /*self.badgeOptions = {
            metric: 'DURATION',
          };*/
        }
        else {
          self.value = Math.round(meters / (self.event.unit == 'METRIC' ? 1000 : 1609.344));
        }
        console.log('distance over course', meters, 'm, score value:', self.value);
        marker.setLatLng(new L.LatLng(position.lat, position.lng));
        map.panTo(new L.LatLng(position.lat, position.lng));
      });

    },

    getNearestPointToPolyline(markerLatLng, polylineLatLngs) {
      var nearestIndex = 0,
          nearestDistance;
          //markerLatLng = latLng,//marker.getLatLng(),
          //polylineLatLngs = polyline.getLatLngs();
      for (var i = 0; i < polylineLatLngs.length; i++) {
        var distance = markerLatLng.distanceTo(polylineLatLngs[i]) //distance en mètres
        if (!nearestDistance || nearestDistance > distance) {
          nearestIndex = i
          nearestDistance = distance
        }
      }
      return polylineLatLngs[nearestIndex];
    },

    getDistanceTillCoord(latLngs, marker) {
      var distOverTrack = 0;
      var prev = latLngs[0];
      for (const latLng of latLngs) {
        const dist = prev.distanceTo(latLng);
        distOverTrack += dist;
        if (latLng.lat == marker.lat && latLng.lng == marker.lng) {
          return distOverTrack;
        }
        prev = latLng;
      }
      return distOverTrack;
    },

  },
  computed: {
    isCustomMap() {
      return this.race && this.race.custom_map && this.race.custom_map.img;
    },
    startCoord() {
      return this.routePolyDecoded == null ? null : this.routePolyDecoded[0];
    },
    stopCoord() {
      return this.routePolyDecoded == null ? null : this.routePolyDecoded[this.routePolyDecoded.length - 1];
    },
  },
  watch: {
    async visible(value) {
      if (value) {
        await this.$nextTick();
        await this.ensureMapLoaded();
      } 
    },
    async race() {
      this.polygon = null;
      if (this.map) {
      }
    }
  },
};
</script>

<style lang="scss">
  .full-dialog { width:100vw;height:100vh; }
</style>